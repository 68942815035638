import React, { useContext, useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { ThemeContext } from './theme-provider';
import PortableText from './portable-text';
import Close from '../svg/close.svg';

export default function ModalContent({ title, content }) {
  const [backgroundAnimation, setBackgroundAnimation] = useState(null);
  const [backgroundXOffset, setBackgroundXOffset] = useState(0);
  const [backgroundYOffset, setBackgroundYOffset] = useState(0);

  const theme = useContext(ThemeContext);

  useEffect(() => {
    if (theme.showModal) {
      setBackgroundAnimation(setInterval(() => {
        setBackgroundXOffset(parseInt(Math.random() * (1920), 10));
        setBackgroundYOffset(parseInt(Math.random() * (1080), 10));
      }, 1000 / 16));
    }
  }, [theme.showModal]);
  return (
    <div
      css={css`
        display: ${(theme.showModal) ? 'block' : 'none'};
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-image: url('/images/background-static.png');
      `}
      style={{
        backgroundPosition: `${backgroundXOffset}px ${backgroundYOffset}px`,
      }}
    >
      <div
        css={css`
          position: absolute;
          top: var(--margin);
          right: calc(var(--bigMargin) + var(--gutter));
          cursor: pointer;

          @media (max-width: 1080px) {
            top: 14.5rem;
          }

          @media (max-width: 700px) {
            top: 12rem;
            right: 4rem;
          }
        `}
        onClick={() => {
          theme.setShowModal(false);
          clearInterval(backgroundAnimation);
        }}
      >
        <Close
          css={css`
            position: relative;
            top: -2.5rem;
            width: 6rem;
            height: auto;
            z-index: 1;

            &:hover {
              circle, path {
                stroke: var(--primary);
                transition: stroke 0s;
              }
            }

            circle, path {
              stroke: var(--black);
              transition: stroke 0.6s var(--curve);
            }
          `}
        />
      </div>
      <div
        className="type--45"
        css={css`
          position: absolute;
          top: 0;
          right: var(--bigMargin);
          bottom: 0;
          left: var(--bigMargin);
          color: var(--black);
          background-color: var(--white);
          padding: var(--margin);
          overflow-y: scroll;

          @media (max-width: 1080px) {
            top: 10rem;
          }

          @media (max-width: 700px) {
            top: 7.5rem;
            right: var(--gutter);
            left: var(--gutter);
          }
        `}
      >
        {theme.modalContent && <PortableText
          blocks={theme.modalContent}
        />}
      </div>
    </div>
  )
}
